// Function to validate the saved JWT token
export const validateToken = () => {
    const savedToken = localStorage.getItem('jwt');
    const refreshToken = localStorage.getItem('refreshToken');
    if (!savedToken && !refreshToken) {
        return false; // Token is invalid or missing
    }
    return savedToken; // Token is valid
};

// authHelper.js
export const refreshToken = async () => {
    const storedRefreshToken = localStorage.getItem("refreshToken");
    if (!storedRefreshToken) {
        return Promise.reject("No refresh token found.");
    }

    try {
        const response = await fetch(`${import.meta.env.VITE_APP_API_PORT}/api/potatomy/refreshToken`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ refreshToken: storedRefreshToken }), // Send JSON object
        });

        if (!response.ok) {
            throw new Error("Failed to refresh token.");
        }

        const data = await response.json();
        localStorage.setItem("jwt", data.token);
        localStorage.setItem("refreshToken", data.refreshToken);
        return data.token;
    } catch (error) {
        console.error("Error refreshing token:", error);
        throw error;
    }
};



