import FFImage1 from '../assets/images/frenchfrie/FFLarge.png';
import FFImage2 from '../assets/images/frenchfrie/FFJumbo.png';
import FFImage3 from '../assets/images/frenchfrie/FFMega.png';
import FFImage4 from '../assets/images/frenchfrie/FFGiga.png';
import FFImage5 from '../assets/images/frenchfrie/FFTera.png';

import WBFFBanner from '../assets/images/banner/WBFFBanner.jpg';

function WorldBestFlavorFrie() {
    const FFData = [
        {
            id: 1,
            picture: FFImage1,
            description: "Large 1 flavor"
        },
        {
            id: 2,
            picture: FFImage2,
            description: "Jumbo 1 flavor"
        },
        {
            id: 3,
            picture: FFImage3,
            description: "Mega 2 flavors"
        },
        {
            id: 4,
            picture: FFImage4,
            description: "Giga 3 flavors"
        },
        {
            id: 5,
            picture: FFImage5,
            description: "Tera 4 flavors"
        }
    ]

    return (
        <>
            <div className='container'>
                <div className="section-head fw-bold mt-4 border-2 border-bottom">
                    <h2>World's Best Flavored Fries</h2>
                </div>
                <div className="artwork-banner">
                    <img src={WBFFBanner} className="center-image" alt="..." />
                </div>
                <div className="py-4">
                    <span>
                    Potato Corner takes pride in serving the best and premium flavored french fries you can't get anywhere else. We turn your ordinary french fries into something more. 
                    Our fries are sourced from best grade potatoes from Europe and enhanced with a range of rich seasonings you can choose from.
                    </span>
                </div>
                <div className="swp-container">
                    {FFData.map(item => (
                        <div className="swp-item" key={item.id}>
                            <img src={`${item.picture}`} className="shake-on-hover" alt="..." />
                        </div>
                    ))}
                </div>
                <div className="py-4">
                    <span>
                    Our fries are fried to a golden crisp but leaves the inside light and soft. It's always cooked at the right temperature and 
                    served hot and fresh for you to enjoy.
                    </span>
                </div>
            </div>
        </>
    )
}

export default WorldBestFlavorFrie