import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [token, setToken] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        // Check if token already exists in localStorage and redirect
        const savedToken = localStorage.getItem('jwt');
        if (savedToken) {
            navigate('/brandambassador');
        }
    }, [navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${import.meta.env.VITE_APP_API_PORT}/api/potatomy/login`, {
                username,
                password,
            });
            setToken(response.data.token);
            localStorage.setItem("jwt", response.data.token);
            localStorage.setItem("refreshToken", response.data.refreshToken);
            navigate('/brandambassador'); // Redirect to /brandAMB
        } catch (error) {
            console.log(error)
            // Check if the error is a response error (e.g., 401)
            if (error.response && error.response.status === 401) {
                const errorMessage = error.response.data.message || "Unauthorized access.";
                Swal.fire({
                    text: errorMessage, // Display the specific error message
                    icon: "error",
                });
            } else {
                // Handle other errors
                Swal.fire({
                    text: "An unexpected error occurred. Please try again.",
                    icon: "error",
                });
            }
        }
    };
    // Condition to enable/disable the button
    const isButtonDisabled = username.length < 3 || password.length < 3;
    return (
        <div className="d-flex justify-content-center  vh-100">
            <div style={{ maxWidth: '400px', width: '100%', }} >
                <div className="border rounded mt-5">
                    <div className="text-center mb-4 fw-bold text-b mt-3">
                        {/* <img src="your-logo-url.png" alt="Logo" className="img-fluid" style={{ maxWidth: '150px' }} /> */}
                        Sign In
                    </div>
                    <form onSubmit={handleSubmit} className="p-4 shadow-sm">
                        <div className="mb-3">
                            <div>Username</div>
                            <input
                                type="text"
                                placeholder="Username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                className="form-control shadow-none mt-1"
                            />
                        </div>
                        <div className="mb-3">
                            <div>Password</div>
                            <input
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="form-control shadow-none mt-1"
                            />
                        </div>
                        <button type="submit" disabled={isButtonDisabled} className="btn w-100">Sign In</button>
                    </form>
                </div>

                {/* {token && <p className="mt-3 text-center">JWT Token: {token}</p>} */}
            </div>
        </div>
    );
};

export default Login;
