import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import LogoMenu from "src/assets/images/logo_menu.png";

const NavbarComponent = ({ handleClick }) => {
    const navigate = useNavigate();

    // Function to check login status directly from localStorage
    const isUserLoggedIn = () => {
        const jwt = localStorage.getItem("jwt");
        const refreshToken = localStorage.getItem("refreshToken"); // Example of another key
        return !!jwt && !!refreshToken; // Returns true if both keys exist
    };


    const handleLogout = () => {
        localStorage.removeItem("jwt"); // Remove JWT token
        localStorage.removeItem("refreshToken"); // Remove JWT token
        navigate("/login_brandamb"); // Redirect to login page
    };

    const myStyle = {
        height: "auto",
        width: "175px",
    };

    return (
        <nav className="navbar navbar-expand-lg bg- shadow-sm">
            <div className="container">
                <a className="navbar-brand" href="#">
                    <img src={LogoMenu} alt="Bootstrap" style={myStyle} />
                </a>
                <button
                    className="navbar-toggler ms-auto"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNavDropdown"
                    aria-controls="navbarNavDropdown"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <NavLink className="nav-link menuLink-style text-dark fw-bold" to="/" onClick={handleClick}>
                                Home
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                className="nav-link menuLink-style text-dark fw-bold"
                                to="/flavors"
                                onClick={handleClick}
                            >
                                Flavors
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                className="nav-link menuLink-style text-dark fw-bold"
                                to="/worldbestflavorfrie"
                                onClick={handleClick}
                            >
                                World's Best Flavored Fries
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                className="nav-link menuLink-style text-dark fw-bold"
                                to="/superchickenpop"
                                onClick={handleClick}
                            >
                                Super Chicken Pop
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link menuLink-style text-dark fw-bold" to="/menu" onClick={handleClick}>
                                Menu
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                className="nav-link menuLink-style text-dark fw-bold"
                                to="/locations"
                                onClick={handleClick}
                            >
                                Locations
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                className="nav-link menuLink-style text-dark fw-bold"
                                to="/getintouch"
                                onClick={handleClick}
                            >
                                Get In Touch
                            </NavLink>
                        </li>
                        {/* Show Profile and Logout only when logged in */}
                        {isUserLoggedIn() && (
                            <>
                                <li className="nav-item">
                                    <NavLink
                                        className="nav-link menuLink-style text-dark fw-bold"
                                        to="/brandambassador"
                                        onClick={handleClick}
                                    >
                                        Profile
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <a
                                        href="#"
                                        className="nav-link menuLink-style text-dark fw-bold"
                                        onClick={(e) => {
                                            e.preventDefault(); // Prevent default anchor behavior
                                            handleLogout();
                                        }}
                                    >
                                        Logout
                                    </a>
                                </li>
                            </>
                        )}
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default NavbarComponent;
