import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebookSquare, faTiktok } from '@fortawesome/free-brands-svg-icons';

function Footer() {

    return (
        <>
            <footer className="container-fluid bg-secondary-subtle mt-3" >
                <div className="footer-head text-center">
                    Follow us
                    <a href="https://www.facebook.com/PotatoCornerMY/" target="_blank">
                        <FontAwesomeIcon icon={faFacebookSquare}/>
                    </a>
                    <a href="https://www.instagram.com/potatocornermalaysia/" target="_blank">
                        <FontAwesomeIcon icon={faInstagram}/>
                    </a>
                    <a href="https://www.tiktok.com/@potatocornermy/" target="_blank">
                        <FontAwesomeIcon icon={faTiktok}/>
                    </a>
                </div>
                <div className="copyRight text-center">
                    Copyright © 2024 Potato Corner Malaysia
                </div>
            </footer>
        </>
    )
}

export default Footer