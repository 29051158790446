import CKPImage1 from '../assets/images/chickenpop/CKPLarge.png';
import CKPImage2 from '../assets/images/chickenpop/CKPJumbo.png';
import CKPImage3 from '../assets/images/chickenpop/CKPMega.png';
import CKPImage4 from '../assets/images/chickenpop/CKPGiga.png';
import CKPImage5 from '../assets/images/chickenpop/CKPTera.png';

import CKPBanner from '../assets/images/banner/CKPBanner.jpg';

function SuperChickenPop() {

    const CKPData = [
        {
            id: 1,
            picture: CKPImage1,
            description: "Large 1 flavor"
        },
        {
            id: 2,
            picture: CKPImage2,
            description: "Jumbo 1 flavor"
        },
        {
            id: 3,
            picture: CKPImage3,
            description: "Mega 2 flavors"
        },
        {
            id: 4,
            picture: CKPImage4,
            description: "Giga 3 flavors"
        },
        {
            id: 5,
            picture: CKPImage5,
            description: "Tera 4 flavors"
        }
    ]

    return (
        <>
            <div className='container'>
                <div className="section-head fw-bold mt-4 border-2 border-bottom">
                    <h2>Super Chicken Pop</h2>
                </div>
                <div className="artwork-banner">
                    <img src={CKPBanner} className="center-image" alt="..." />
                </div>
                <div className="py-4">
                    <span>
                        We've always been told that our Super Chicken Pop is simply the best and we can't argue with that! Potato Corner's chicken pop is juicy, flavorful and irresistible.
                        They are made from real chicken meat and soft tender thighs. Every bite leaves you wanting more and more.
                    </span>
                </div>
                <div className="swp-container">
                    {CKPData.map(item => (
                        <div className="swp-item" key={item.id}>
                            <img src={`${item.picture}`} className="shake-on-hover" alt="..." />
                        </div>
                    ))}
                </div>
                <div className="py-4">
                    <span>
                        Just like our fries, they're deep fried at the best temperature to obtain that crispy crunch without compromising its juicy meaty texture. 
                        Seasoned well with our signature flavors and voila! —you've got yourself a mouth watering bite-sized chicken sensation.
                    </span>
                </div>
            </div>
        </>
    )
}

export default SuperChickenPop